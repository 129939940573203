<script setup>
</script>

<template>
  <div class="app-bg">
    <div class="blobs">
      <img
        style="top: 560px; left: 80%; position: absolute; width: 623.65px;"
        src="../assets/images/2024/About/prism-1.png"
        aria-hidden="true"
      />
    </div>
    <img
        style="top: 700px; position: absolute; height:1387px;"
        src="../assets/images/2024/About/shadow-2.png"
        aria-hidden="true"
      />
  </div>

  <div class="about">
    <section class="organizer-section">
      <h2>ABOUT US</h2>
      <h3 class="header">Get to know the organizations <br> behind the event!</h3>
      
      <div class="copy">
        <div class="white-card">
          <img
            src="../assets/images/2024/About/misa-logo-dark.png"
            alt="MISA logo"
            class="misa-logo"
          />
          <br><h5>Ateneo Management Information Systems Association</h5><br>
          <p>
            The Ateneo Management Information Systems Association (MISA) is the Ateneo Loyola Schools'
            premier information management organization which serves as a home for MIS majors and
            business-minded tech enthusiasts. Founded in 1994, it is now on its 28th year pursuing its
            main advocacy — social transformation through information management.
          </p>
        </div>
        <div class="label">
          <br>
          <h4>The <br>Organizer</h4>
        </div>
      </div>
      <div class="label-sm">
        <br>
        <h4>The <br>Organizer</h4>
      </div>
      <div class="copy">
        <div class="white-card" style="top:70px; margin-bottom: 50px;">
          <img
            src="../assets/images/2024/About/Home-Credit-logo.png"
            alt="Home Credit logo"
            class="home-credit-logo"
          />
          <br><h5>Empowering people to live the life <br>they want now.</h5><br>
          <p>
            Founded in 1997, Home Credit is an international consumer finance provider with operations in eight countries. We focus on responsible lending primarily to people with little or no credit history. Our services are simple, easy and fast. 
          </p>
        </div>
        <div class="label" style="text-align:left;">
          <h4>The <br>Case Presenter</h4>
          <br>
          <a class="btn-link" href="https://www.homecredit.ph/?fbclid=IwAR1Ex1ySxQlJhj-Ginnf-eCC5FjW0YjmcODCqdZRI3qyjsIKUULcxXsKMKA">
            Learn More
          </a>
        </div>
      </div>
      <div class="label-sm" style="text-align:left; margin-top: 20px;">
        <br>
        <h4>The <br>Case Presenter</h4>
      </div>
      <div class="label-sm">
        <a class="btn-link" href="https://www.homecredit.ph/?fbclid=IwAR1Ex1ySxQlJhj-Ginnf-eCC5FjW0YjmcODCqdZRI3qyjsIKUULcxXsKMKA">
          Learn More
        </a>
      </div>
    </section>
  </div>
</template>

<style scoped>
.about section {
  max-width: var(--container-width);
  margin-inline: auto;
  padding-inline: 1rem;
  padding-block: 7rem 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.copy {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4rem;
  right: 15%;
}

h2,
h3,
h4,
h5,
p {
  max-width: 110ch;
}

h2 {
  color: #F77DD1;
  position: absolute;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 16px;
}

h3 {
  font-size: 52px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  line-height: 1.1;
  color: #221B35;
}

h4 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 40px;
  color: black;
  text-align: left;
  width: 380px;
}

h5 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 30px;
  line-height: 1.1;
  color: #221B35;
  text-align: left;
}

p {
  color:black;
  text-align: left;
  font-size: 20px;
}

.organizer-section .misa-logo {
  width: 313px;
  max-width: 60%;
  margin-left: auto;
  margin-right: auto;
}

.header {
  margin-block: 3rem;
}
.white-card {
  background-color: var(--white);
  padding: 5rem 3rem;
  max-width: 652px;
  max-height: 700px;
  margin-inline: auto;
  box-shadow: 4px 4px 4px #E8E9EB;
  border-radius: 24px;
  z-index: 1;
  position: relative;
}

.organizer-section .home-credit-logo {
  width: 289px;
  max-width: 60%;
  margin-left: auto;
  margin-right: auto;
}
</style>

<style scoped>
.app-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  min-height: 100%;
  background: #FFFFFF;
  background-repeat: no-repeat;
  overflow: hidden;
}

.app-bg .blobs {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: var(--container-width);
  width: 100%;
  height: 100%;
}

.images-bg {
  position: absolute;
  background-image: url(../assets/images/2023/grain.png);
  mix-blend-mode: soft-light;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  min-height: 100%;
  z-index: -1;
}

.label-sm {
  display: none;
}

@media (max-width:900px) {
  
  h2 {
    font-weight: 700;
    font-size: 18px;
  }

  h3 {
    font-size: 32px;
    font-weight: 700;
  }

  h4 {
    font-weight: 500;
    font-size: 32px;
    width: 380px;
  }

  h5 {
    font-weight: 500;
    font-size: 22px;
  }

  p {
    font-size: 12px;
  }
  .app-bg {
    display: none;
  }
}

@media (max-width:480px) {
  .label {
    display: none;
  }

  .label-sm {
    display: flex;
  }
  .label-sm h4 {
    text-align: center;
  }

  h2 {
    font-weight: 700;
    font-size: 16px;
  }

  h3 {
    font-size: 30px;
    font-weight: 700;
  }

  h4 {
    font-weight: 500;
    font-size: 30px;
    width: 380px;
  }

  h5 {
    font-weight: 500;
    font-size: 20px;
  }

  p {
    font-size: 10px;
  }
  .app-bg {
    display: none;
  }
}

</style>
